<template>
    <section class="login">
      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div class="content-wrapper d-flex align-items-center auth">
            <div class="row w-100 flex-grow">
              <div class="col-xl-4 col-lg-6 mx-auto">
                <div class="auth-form-light text-left p-5 same_modal">
                  <div class="brand-logo text-center">
                    <img src="@/assets/images/logo-footer.webp" style="width: 100px;">
                  </div>
                  <h4 class="text-center">Two Step Verification</h4>
                  <h6 class="font-weight-light text-center"> Enter the verification code we sent to</h6>
                  <form class="pt-3" @submit.prevent="onSubmit" >


                    

                <div class="mb-10 px-md-10">
                <div class="fw-bolder text-center text-dark fs-6 mb-1 ms-1">
                  Type your 6 digit security code
                </div>

                    <div class="d-flex flex-wrap flex-stack justify-content-center">
                  <input
                    type="number"
                    data-inputmask="'mask': '9', 'placeholder': ''"
                    maxlength="1"
                    class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                    value="3"
                    v-model="vcode[0]"
                    required
                    style="width:14%; height:60px;"
                    ref="vcode0"
                    @keydown="onKeydown"
                    v-on:keyup="$event.target.nextElementSibling.focus()"
                  />
  
                  <input
                    type="number"
                    data-inputmask="'mask': '9', 'placeholder': ''"
                    maxlength="1"
                    class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                    value="0"
                    v-model="vcode[1]"
                    required
                    style="width:14%; height:60px;"
                    ref="vcode1"
                    @keydown="onKeydown"
                    v-on:keyup="$event.target.nextElementSibling.focus()"
                  />
  
                  <input
                    type="number"
                    data-inputmask="'mask': '9', 'placeholder': ''"
                    maxlength="1"
                    class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                    value="7"
                    v-model="vcode[2]"
                    required
                    style="width:14%; height:60px;"
                    @keydown="onKeydown"
                    v-on:keyup="$event.target.nextElementSibling.focus()"
                  />
  
                  <input
                    type="number"
                    data-inputmask="'mask': '9', 'placeholder': ''"
                    maxlength="1"
                    class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                    value=""
                    v-model="vcode[3]"
                    required
                    style="width:14%; height:60px;"
                    @keydown="onKeydown"
                    v-on:keyup="$event.target.nextElementSibling.focus()"
                  />
  
                  <input
                    type="number"
                    data-inputmask="'mask': '9', 'placeholder': ''"
                    maxlength="1"
                    class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                    value=""
                    v-model="vcode[4]"
                    required
                    style="width:14%; height:60px;"
                    @keydown="onKeydown"
                    v-on:keyup="$event.target.nextElementSibling.focus()"
                  />
  
                  <input
                    type="number"
                    data-inputmask="'mask': '9', 'placeholder': ''"
                    maxlength="1"
                    class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                    value=""
                    v-model="vcode[5]"
                    required
                    style="width:14%; height:60px;"
                    @keydown="onKeydown"
                    v-on:keyup="$event.target.nextElementSibling.focus()"
                  />
                </div>
                </div>

                <div class="d-flex flex-center my-4 px-3">
                <button
                  type="button"
                  id="kt_sing_in_two_steps_submit"
                  class="btn btn-lg same_btn fw-bolder w-100"
                  v-on:click="sendCode"
                >
                  <span class="indicator-label">Submit</span>
                  <!-- <span class="indicator-progress"
                    >Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                  ></span> -->
                </button>
                <br />
              </div>

              <div class="text-center fw-bold fs-5">
            <span class="text-muted me-1">Didn’t get the code ?</span>
            <router-link class="link-primary fw-bolder fs-5 me-1" to="/forgetPassword"
              >Resend</router-link
            >
            <!-- <span class="text-muted me-1">or</span> -->
            <!-- <a href="#" class="link-primary fw-bolder fs-5">Call Us</a> -->
          </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- content-wrapper ends -->
        </div>
        <!-- page-body-wrapper ends -->
      </div>
      <vue-snotify></vue-snotify>
    </section>
  
  </template>
  
  <script>
  import api from "../../config/api.js";
  import useVuelidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  export default {
    name: "CodeVerify",
  
    data() {
      return {
        vcode: [],
  
        error: null,
        vfcode: [],
        email: "",
        token: "",
      };
    },
    setup() {
      return { v$: useVuelidate() };
    },
  
    validations() {
      return {
        vcode: { [0]: required },
      };
    },
    mounted() {
      // this.$refs.vcode0.$el.focus();
      this.$refs.vcode0.focus();
    },
  
    methods: {
      onKeydown(event) {
        const char = String.fromCharCode(event.keyCode);
        if (!/[0-9]/.vcode(char)) {
          event.preventDefault();
          event.target.nextElementSibling.focus();
        }
      },
  
      async sendCode() {
        try {
          this.email = this.$store.state.email;
          this.error = null;
  
          this.vfcode =
            this.vcode[0] +
            this.vcode[1] +
            this.vcode[2] +
            this.vcode[3] +
            this.vcode[4] +
            this.vcode[5];
  
          let result = await api.post(`/admin/send-token`, {
            email: this.email,
            reset_code: this.vfcode,
          });
          console.log(result);
          this.token = result.data.token;
        //   this.$toast.success(result.data.message + "Please Set New Password");
          this.$swal('Success!', result.data.message + "Please Set New Password" , 'success')
          this.$store.commit("gettoken", this.token);
          this.$router.push({ name: "confirmpassword" });
        } catch (error) {
          this.error = error.response.data.message;
        //   this.$toast.error(this.error);
        this.$swal('Error!', this.error , 'error')
        }
      },
  
      resendCode() {
        try {
          this.sendCode();
        } catch (error) {
          this.error = error.response.data.message;
          this.$toast.error(this.error);
        }
      },
    },
  };
  </script>
  
  <style scoped></style>
  